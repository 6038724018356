<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('deviceList.sn')"
        v-model.trim="params.sn"
        clearable
        @keyup.enter.native="replaceData"
      />
      <SelectOrgan
        v-model="params.spId"
        :placeholder="$t('deviceModal.selectCustomer')"
        canSelectMerchant
        onlySelectMerchant
        :cascaderProps="{ emitPath: false, label: 'name', value: 'id' }"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column prop="time" :label="$t('customerStatistical.warningTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="sn" :label="$t('deviceList.sn')" />
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          :label="$t('deviceList.deviceGroup')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="content"
          :label="$t('customerStatistical.warningContent')"
        ></el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'

export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      tableData: []
    }
  },
  created() {},
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const res = await this.$api.systemManagement.warnInfoMacList(this.params)
        if (res.result) {
          this.tableData = res.result.list || []
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss"></style>
